import styled from 'styled-components';
import { MediaQueries } from 'environment';

export const Container = styled.div`
	display: flex;
	padding: 0 5rem;
	user-select: none;
	position: relative;
	pointer-events: none;

	:first-child {
		padding-left: 10rem;
	}

	:last-child {
		padding-right: 10rem;
	}

	@media ${MediaQueries.phone} {
		:first-child {
			padding-left: 5rem;
		}

		:last-child {
			padding-right: 5;
		}
	}
`;

export const Details = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 5.4rem;
`;

export const Title = styled.h3`
	font-family: 'Open Sans';
	font-weight: 600;
	width: 18rem;
	text-transform: uppercase;
`;

export const Description = styled.p`
	margin-top: 1rem;
`;

export const Image = styled.img`
	width: 28rem;
	margin-top: 2rem;
	margin-bottom: 1rem;
`;
