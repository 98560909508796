import React, { useState, useEffect } from 'react';

import { MediaQueries } from 'environment';
import { getMemberSocialAbb, getMemberTopPosition } from 'helpers';
import { useMediaQuery, useWindowScrollPosition } from 'hooks';
import { Member, Nullable, ImageSharpNode } from 'types';

import {
	AnimatedContainer,
	Details,
	ImageContainer,
	Image,
	Link,
	Name,
	Position,
	Socials
} from './TeamMember.style';
import { useSpring } from 'react-spring';

const DESKTOP_COEFFICIENT = 300;
const TABLET_COEFFICIENT = 500;

interface Props extends Member {
	index: number;
	node: Nullable<ImageSharpNode>;
}

export function TeamMember({ name, image, position, social, index, node }: Props) {
	const isMobile = useMediaQuery(MediaQueries.phone, false);
	const isTablet = useMediaQuery(MediaQueries.tablet);
	const isDesktop = useMediaQuery(MediaQueries.desktop);

	const [left, setLeft] = useState(-100);
	const [top, setTop] = useState<Nullable<number>>(null);
	const [hidden, setHidden] = useState(true);

	useEffect(() => {
		setTop(getMemberTopPosition(index, isMobile, isDesktop));
		setHidden(false);
	}, [index, isMobile, isDesktop]);

	useEffect(() => {
		if (isMobile) {
			setLeft(-50);
		} else {
			setLeft(-100);
		}
	}, [isMobile]);

	useWindowScrollPosition(
		({ previous, current }) => {
			if (isDesktop || isTablet) {
				const scrollCoefficient = isDesktop ? DESKTOP_COEFFICIENT : TABLET_COEFFICIENT;
				const position = Math.round(current.y / scrollCoefficient);

				if (position >= index && previous.y < current.y) {
					setLeft(left => Math.min(0, left + (current.y - previous.y) / 10));
				}

				if (position <= index + 3 && previous.y > current.y) {
					setLeft(left => Math.max(-100, left - (previous.y - current.y) / 10));
				}
			}
		},
		[isDesktop, isTablet],
		0
	);

	const { opacity } = useSpring({
		opacity: hidden ? 0 : 1
	});

	return (
		<AnimatedContainer style={{ top, opacity }}>
			<ImageContainer>
				{node && <Image fluid={node.fluid} alt={image.alt} style={{ left }} />}
			</ImageContainer>
			<Details>
				<Name>{name}</Name>
				<Position>{position}</Position>
				{social && (
					<Socials>
						{social.map(soc => (
							<Link
								key={`social-${soc.name}-${index}`}
								href={soc.link}
								rel="nofollow noreferrer"
								target="_blank"
							>
								{getMemberSocialAbb(soc.name)}
							</Link>
						))}
					</Socials>
				)}
			</Details>
		</AnimatedContainer>
	);
}
