import styled from 'styled-components';

import { TABLET_GRID_SIZE } from 'consts';
import { MediaQueries } from 'environment';

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 12rem 0;
	position: relative;

	@media ${MediaQueries.phone} {
		padding: 6rem 0;
	}
`;

export const Title = styled.h1`
	margin-bottom: 12rem;
	text-align: center;

	@media ${MediaQueries.phone} {
		font-family: 'Open Sans';
		font-size: 3.6rem;
		font-weight: 600;
		line-height: 5rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
		margin-bottom: 20rem;
	}

	@media ${MediaQueries.minWidth.fhd} {
		margin-bottom: 0;
	}
`;

interface BannerProps {
	src?: string;
}

export const Banner = styled.div<BannerProps>`
	display: flex;
	align-items: center;
	width: 100%;
	transform: rotate(180deg) scaleX(-1);
	background-image: ${({ src }) => `url(${src})`};
	background-position-x: 50%;
	background-size: cover;

	& > div {
		transform: rotate(180deg) scaleX(-1);
		width: 100%;
	}

	@media ${MediaQueries.phone} {
		height: 56rem;
		margin-bottom: 6rem;
	}

	@media ${MediaQueries.tablet} {
		height: 56rem;
		margin-bottom: 4rem;
	}

	@media ${MediaQueries.desktop} {
		background-position-x: 0;
	}

	@media ${MediaQueries.maxWidth.fhd} {
		& > div {
			max-height: 72rem;
		}
	}

	.simplebar-content-wrapper {
		height: 100% !important;
		display: flex;
		align-items: center;
	}
`;

export const Events = styled.div`
	display: flex;
	height: 72rem;
`;
