import React from 'react';
import styled from 'styled-components';

import { TeamHeader, TeamMembers, Timeline } from 'components/Team';
import { SEO, DarkCta } from 'components/UI';
import seo from 'data/seo.json';
import analytics from 'data/analytics.json';
import content from 'data/team.json';
import { Colors, MediaQueries } from 'environment';
import { useMemoOnce } from 'hooks';

export default function AboutUs() {
	const members = useMemoOnce(() => content.members.filter(member => !member.hidden));

	return (
		<>
			<SEO
				title={seo.teamTitle}
				description={seo.teamDescription}
				ogTitle={seo.teamOgTitle}
				ogDescription={seo.teamOgDescription}
				ogImage={seo.teamOgImage}
			/>
			<TeamHeader />
			<TeamMembers members={members} />
			<Timeline title={content.timeline.title} events={content.timeline.events} />
			<DarkCta
				name={analytics.aboutUsContact}
				title={<Title>{content.contact.title}</Title>}
				description={content.contact.subtitle}
				cta={content.contact.cta}
				to="/contact/"
			/>
		</>
	);
}

const Title = styled.h2`
	color: ${Colors.white};
	margin-bottom: 4rem;
	text-align: center;

	@media ${MediaQueries.phone} {
		font-size: 2.4rem;
		line-height: 2.9rem;
		margin-bottom: 2rem;
	}

	@media ${MediaQueries.tablet} {
		font-family: 'Open Sans';
		font-size: 3.6rem;
		font-weight: 600;
		line-height: 5rem;
	}
`;
