import React from 'react';

import { TimelineEvent as Event } from 'types';

import { TimelineMarker } from './TimelineMarker';
import { Container, Description, Details, Image, Title } from './TimelineEvent.style';

export function TimelineEvent({ title, description, position, image, alt }: Event) {
	return (
		<Container
			style={{
				top: position === 'top' ? 0 : position === 'mid' ? 80 : 400
			}}
		>
			<TimelineMarker position={position} />
			<Details>
				<Title>{title}</Title>
				{image && <Image src={image} alt={alt} />}
				<Description>{description}</Description>
			</Details>
		</Container>
	);
}
