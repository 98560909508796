import Img from 'gatsby-image';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { animated } from 'react-spring';
import styled from 'styled-components';

import {
	DESKTOP_GRID_COLUMN,
	DESKTOP_GRID_SPACING,
	MEMBER_IMAGE_WIDTH,
	MEMBER_IMAGE_HEIGHT,
	MEMBER_IMAGE_MOBILE_WIDTH,
	MEMBER_IMAGE_MOBILE_HEIGHT
} from 'consts';
import { Colors, MediaQueries, Sizes } from 'environment';

const Container = styled.div`
	display: flex;

	:nth-child(even) {
		flex-direction: row-reverse;
		right: 0;
	}

	@media ${MediaQueries.phone} {
		margin-top: 2rem;

		:first-child {
			margin-top: 0;
		}
	}

	@media ${MediaQueries.tablet} {
		margin-top: 4rem;

		:first-child {
			margin-top: 7rem;
		}
	}

	@media ${MediaQueries.desktop} {
		position: absolute;

		:nth-of-type(4n + 1) {
			left: ${2 * DESKTOP_GRID_COLUMN + 2 * DESKTOP_GRID_SPACING}rem;
		}

		:nth-of-type(4n + 2) {
			right: 0;

			@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
				right: 2rem;
			}
		}

		:nth-of-type(4n + 3) {
			left: 0;

			@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
				left: 2rem;
			}
		}

		:nth-of-type(4n) {
			right: ${2 * DESKTOP_GRID_COLUMN + 2 * DESKTOP_GRID_SPACING}rem;
		}
	}
`;
export const AnimatedContainer = animated(Container);

export const ImageContainer = styled.div`
	height: ${MEMBER_IMAGE_HEIGHT}rem;
	width: ${MEMBER_IMAGE_WIDTH}rem;
	overflow: hidden;

	@media ${MediaQueries.phone} {
		height: ${MEMBER_IMAGE_MOBILE_HEIGHT}rem;
		max-width: ${MEMBER_IMAGE_MOBILE_WIDTH}rem;
	}
`;

export const Image = styled(Img)`
	pointer-events: none;
	user-select: none;
	position: relative;
	height: ${MEMBER_IMAGE_HEIGHT}rem;
	width: calc(100% + 10rem);

	@media ${MediaQueries.phone} {
		height: ${MEMBER_IMAGE_MOBILE_HEIGHT}rem;
	}
`;

export const Details = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;

	${Container}:nth-child(odd) & {
		margin-left: ${DESKTOP_GRID_SPACING}rem;
		margin-top: 4rem;
	}

	${Container}:nth-child(even) & {
		align-items: flex-end;
		margin-right: ${DESKTOP_GRID_SPACING}rem;
		margin-top: 8rem;
	}

	@media ${MediaQueries.phone} {
		margin-top: 2rem !important;
	}
`;

export const Name = styled.h3`
	font-family: 'Open Sans';
	font-weight: 600;
	word-spacing: 999rem;

	${Container}:nth-child(even) & {
		text-align: right;
	}

	@media ${MediaQueries.phone} {
		font-family: 'Comfortaa';
		font-size: 2.4rem;
		line-height: 2.9rem;
	}
`;

export const Position = styled.span`
	display: block;
	margin-top: 1rem;
	max-width: ${2 * DESKTOP_GRID_COLUMN + DESKTOP_GRID_SPACING}rem;
	text-transform: uppercase;

	${Container}:nth-child(even) & {
		text-align: right;
	}
`;

export const Socials = styled.div`
	display: flex;
	margin-top: 2rem;
`;

export const Link = styled(OutboundLink)`
	color: ${Colors.indigo};
	font-size: 1.4rem;
	font-weight: bold;
	line-height: 2.2rem;
	border-bottom: 0.2rem transparent solid;
	transition: all 0.2s;

	&:hover {
		color: ${Colors.green};
		border-color: ${Colors.green};
	}

	${Container}:nth-child(odd) & {
		margin-right: 1rem;
		z-index: 1;
	}

	${Container}:nth-child(even) & {
		margin-left: 1rem;
	}
`;
