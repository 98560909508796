import styled from 'styled-components';

import {
	DESKTOP_GRID_COLUMN,
	DESKTOP_GRID_SIZE,
	DESKTOP_GRID_SPACING,
	TABLET_GRID_SIZE
} from 'consts';
import { MediaQueries, Sizes } from 'environment';

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding-top: 4rem;
	padding-bottom: 6rem;

	@media ${MediaQueries.tablet} {
		padding-bottom: 5rem;
	}

	@media ${MediaQueries.desktop} {
		padding-bottom: 10rem;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	@media ${MediaQueries.phone} {
		padding: 0 2rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		align-items: center;
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding: 0 2rem;
	}
`;

export const Title = styled.h1`
	text-align: center;
	margin-bottom: 2rem;

	@media ${MediaQueries.phone} {
		font-family: 'Open Sans';
		font-size: 3.6rem;
		font-weight: 600;
		line-height: 5rem;
		margin-bottom: 1rem;
	}
`;

export const Description = styled.p`
	text-align: center;

	@media ${MediaQueries.desktop} {
		align-items: center;
		max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 5}rem;
	}
`;
