import styled, { css } from 'styled-components';
import { Colors } from 'environment';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	top: 2rem;
`;

interface LineProps {
	position: string;
}

export const Line = styled.div<LineProps>`
	width: 0.2rem;
	height: ${({ position }) =>
		position === 'top' ? '64rem' : position === 'mid' ? '56rem' : '24rem'};
	background-color: ${Colors.gray[5]};
`;

export const Dot = styled.div`
	display: flex;
	justify-content: center;
	width: 1.2rem;
	height: 1.2rem;
	background-color: ${Colors.gray[5]};
	border-radius: 50%;
	position: relative;
`;

interface WaveProps {
	index?: number;
}

export const Wave = styled.div<WaveProps>`
	width: 3rem;
	height: 3rem;
	background-color: ${Colors.gray[5]};
	border-radius: 50%;
	opacity: 0.5;
	position: absolute;
	bottom: -0.9rem;

	${({ index }) =>
		index === 1 &&
		css`
			width: 5.4rem;
			height: 5.4rem;
			opacity: 0.3;
			bottom: -2.1rem;
		`}

	${({ index }) =>
		index === 2 &&
		css`
			width: 8rem;
			height: 8rem;
			opacity: 0.1;
			bottom: -3.4rem;
		`}
`;
