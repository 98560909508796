import React from 'react';

import { Container, Dot, Line, Wave } from './TimelineMarker.style';

interface Props {
	position: string;
}

export function TimelineMarker({ position }: Props) {
	return (
		<Container>
			<Dot />
			<Line position={position} />
			<Dot>
				<Wave />
				<Wave index={1} />
				<Wave index={2} />
			</Dot>
		</Container>
	);
}
