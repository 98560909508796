import React, { useRef, useState } from 'react';
import { useDrag } from 'react-use-gesture';

import { CustomScrollbar } from 'components/UI';
import { MediaQueries } from 'environment';
import { useEffectOnce, useMediaQuery, useQueryContext, useWindowSize } from 'hooks';
import { TimelineEvent } from 'types';

import { TimelineEvent as TEvent } from './TimelineEvent';
import { Banner, Container, Events, Title } from './Timeline.style';
import './Timeline.scss';

interface Props {
	title: string;
	events: TimelineEvent[];
}

export function Timeline({ title, events }: Props) {
	const isPhone = useMediaQuery(MediaQueries.phone);
	const isTablet = useMediaQuery(MediaQueries.tablet);
	const { images } = useQueryContext();

	const ref = useRef<any>(null);
	const [scrollDistance, setScrollDistance] = useState(0);

	useEffectOnce(() => {
		if (ref.current) {
			const element = ref.current.getScrollElement();
			setScrollDistance(element.scrollWidth - element.clientWidth);
		}
	});

	const bind = useDrag(
		({ offset: [x] }) => {
			if (ref.current) {
				ref.current.getScrollElement().scrollLeft = -x;
			}
		},
		{ bounds: { right: 0, left: -scrollDistance }, rubberband: true }
	);

	return (
		<Container>
			<Title>{title}</Title>
			<Banner
				src={
					isPhone
						? images.banners.landing.mobile?.fluid.src
						: isTablet
						? images.banners.landing.tablet?.fluid.src
						: images.banners.landing.desktop?.fluid.src
				}
			>
				<CustomScrollbar {...bind()} ref={ref}>
					<Events>
						{events.map(event => (
							<TEvent key={`timeline-${event.title}`} {...event} />
						))}
					</Events>
				</CustomScrollbar>
			</Banner>
		</Container>
	);
}
