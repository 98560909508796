import React from 'react';

import content from 'data/team.json';

import { Container, Content, Description, Title } from './TeamHeader.style';

export function TeamHeader() {
	return (
		<Container>
			<Content>
				<Title>{content.title}</Title>
				<Description>{content.description}</Description>
			</Content>
		</Container>
	);
}
