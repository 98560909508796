import styled from 'styled-components';

import { Banner as BaseBanner } from 'components/UI/Banner';
import { DESKTOP_GRID_SIZE, TABLET_GRID_SIZE } from 'consts';
import { MediaQueries } from 'environment';

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	position: relative;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;

	@media ${MediaQueries.phone} {
		padding: 0 2rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		align-items: center;
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}
`;

export const Banner = styled(BaseBanner)`
	@media ${MediaQueries.phone} {
		top: 1rem;
	}

	@media ${MediaQueries.tablet} {
		top: 2rem;
	}
`;
