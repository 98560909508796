import React from 'react';

import { MediaQueries } from 'environment';
import { getMembersHeight } from 'helpers';
import { useMediaQuery, useQueryContext } from 'hooks';
import { Member } from 'types';

import { TeamMember } from './TeamMember';
import { Banner, Container, Content } from './TeamMembers.style';

interface Props {
	members: Member[];
}

export function TeamMembers({ members }: Props) {
	const { images } = useQueryContext();
	const isDesktop = useMediaQuery(MediaQueries.desktop);

	return (
		<Container>
			{images.banners.landing && <Banner image={images.banners.landing} />}
			<Content style={{ height: getMembersHeight(members.length, isDesktop) }}>
				{members.map((member, i) => (
					<TeamMember
						{...member}
						key={`team-member-${i}`}
						index={i}
						node={images.members[i]}
					/>
				))}
			</Content>
		</Container>
	);
}
